import React, {useEffect} from "react"
import Layout from "../components/layout"
// import Img from "gatsby-image"
import SEO from "../components/seo"

import "../styles/about_us.scss"
import {graphql} from "gatsby";
import AboutBg from "../images/tech_bg.png";
import planet from "../images/planet6.png";
import flyOne from "../images/letelice.png";
import flyTwo from "../images/letelice desne.png";

const About = (props) => {
    useEffect(() => {
        if (window) {

        }
    }, [])
return (
    <Layout>
        <SEO title="About us – S7 Code & Design" keywords={[`about us`, `s7 code & design`, `mission`, `vision`]}
        description="Do you know who we are and how we do? Take a look at our Mision an Vision
and see what is our main goals."
        />
        <div className="about-main">
            <div className="row about-main-logo" style={{ backgroundImage: `url(${planet})` }} id="about-main-logo">
                <img className="fly-one levitate" src={flyOne}/>
                <img className="fly-two levitate" src={flyTwo}/>
            </div>
            <div className="inner">

                <div className="offset-xl-5 col-xl-6 col-12">
                    <div className='about-main--heading'>
                        <h1 className='text-uppercase text-center mt-5'>About us</h1>
                        <p>S7 Code & Design is a software development company based in Novi Sad, Serbia, founded in
                            2012. From the beginning, our company has built its future in a reliable partnership with
                            its clients as a service and professional support.</p>

                        <p>The company specializes in custom software and website development using open source
                            technologies who provide stability and flexibility through the entire development cycle.</p>
                        <p>With a long experience in implementing software products, we offer various development
                            outsourcing services with reliable cost-effective solutions.
                            As a software development company that keeps up-to-date with the latest technologies,
                            we can respond to the most demanding market needs with our knowledge and experience.</p>
                        <p>We are continuously improving the quality of products and services using Agile management
                            methods who help us to minimize the project risk and reduce the overall delivery time requirements.</p>
                        <p>Within the years of working with diverse companies worldwide, S7 Code & Design attained
                            valuable skills, experience and technological expertise to start a project of any scale and scope.</p>
                            <h2 className='text-uppercase text-center'>Mission and vision</h2>
                        <p className=''>We are passionate about helping businesses to achieve results that
                            benefit everyone, not just specific individuals or enterprises. Our mission is to deliver
                            the right technology to assist our clients in accomplishing their goals.
                            Our vision is to help the world run better and improve people's lives through our solutions.
                        </p>
                    </div>
                </div>
            </div>
            <div className="row justify-content-center" style={{position:'relative', width:'100%'}}>
                <div className='col-md-8 col-12 about'>
                    <div className='about--inner'>
                        {/* <h2 className='text-uppercase text-center'>Mission and vision</h2>
                        <p className='font-italic'>We are passionate about helping businesses to achieve results that
                            benefit everyone, not just specific individuals or enterprises. Our mission is to deliver
                            the right technology to assist our clients in accomplishing their goals.
                            Our vision is to help the world run better and improve people's lives through our solutions.
                        </p> */}
                    </div>
                </div>
                {/*<div className="balloons-wrapper">*/}
                    {/*<div id="balloons">*/}
                        {/*<div className="balloons bsmall BC-1">*/}
                            {/*<Img fluid={props.data.anotherballoon.childImageSharp.fluid} />*/}
                        {/*</div>*/}
                        {/*<div className="balloons bsmall BC-2">*/}
                            {/*<Img fluid={props.data.balloon.childImageSharp.fluid} />*/}
                        {/*</div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                <div className="about-main-bg">
                    <img className="black" src={AboutBg} alt={""}/>
                </div>
            </div>
        </div>
    </Layout>
    )
}

export default About

export const pageQuery = graphql`
  query {
    balloon: file(relativePath: { eq: "yellow.png" }) {
      ...fluidImage
    }
    anotherballoon: file(relativePath: { eq: "balloon.png" }) {
      ...fluidImage
    }
  }
`
